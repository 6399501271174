import './App.css';
import { Navbar } from './common/navbar';
import {Route, Routes} from "react-router-dom";
import { Home } from './pages';

function App() {
  return (
    <div className="App">
      <Navbar />
        <Routes>
          <Route path="/" element={<Home />}/>
        </Routes>
    </div>
  );
}

export default App;
