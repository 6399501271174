import React from "react";
import styles from "./index.module.css"
import { NavItem } from "./components/NavItem";

export function NavMenu(props){
    return(
        <div className={styles.container}>
            <div className={props.condition ? styles.mobileNavMenu : styles.navmenu}>
            <NavItem duration={250} link='home' label="Home"/>
            <NavItem duration={500} link='about' label="About"/>
            <NavItem duration={1200} link='services' label="Services"/>
            {/* <NavItem duration={1000} link="blog" label="Blog"/> */}
            <NavItem duration={2200} link='contact' label="Contact"/>
        </div>
        </div>
    );
}