import React from 'react'
import styles from './index.module.css'
import Box from './components/box'
import one from '../../assets/images/one.jpg'
import two from '../../assets/images/two.png'
import three from '../../assets/images/three.jpg'

export default function About() {
  return (
    <>
        <div className={styles.container} id="about">
            <h1>About Us</h1>
            <div className={styles.topside}>
                <h2>Our Mission:</h2>
                <p>
                Personal and organizational success. Simultaneously, we are dedicated to addressing the vital needs of
                healthcare institutions by assisting them in finding both qualified talent and optimal patient care solutions. By
                fostering a dynamic platform that harmonizes expertise with opportunity, we aim to empower individuals to
                thrive in their chosen fields while supporting hospitals in delivering top-tier care. Our commitment extends
                beyond job placement; we aspire to build a resilient, interconnected ecosystem where professionals flourish,
                hospitals prosper, and every worker finds purpose in contributing to the well-being of patients.
                </p>
            </div>

            <div className={styles.bottomside}>
                <h2>Team Photos:</h2>
                <div className={styles.gallery}>
                    <Box image={one} alt="our team photo one" key={1}/>
                    <Box image={two} alt="our team photo two" key={2}/>
                    <Box image={three} alt="our team photo three" key={3}/>
                </div>
            </div>
        </div>
    </>
  )
}
