import React from "react";
import styles from "./index.module.css"
import {Link} from "react-scroll"

export function NavItem({duration, link, label}){
    return(
        <>
            <Link className={styles.navitem} spy={true} smooth={true} offset={50} duration={duration} to={link}>{label}</Link>
        </>
    );
}