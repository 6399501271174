import React from 'react'
import styles from './index.module.css'
import Card from './components/card'
import carePhoto from '../../assets/images/care.png'
import careersPhoto from '../../assets/images/careers.jpg'

export default function Services() {

  //names of the services based on the category
  const care = [
      {id:1, service:"Alzheimer's"},
      {id:2, service:"Dementia"}, 
      {id:3, service:"Mentally health"}, 
      {id:4, service:"Sick"}
    ];
  const careers = [
      {id:1, service:"Find people"},
      {id:2, service:"Find clients"}, 
      {id:3, service:"Find workers jobs"}
    ];

  return (
    <div className={styles.container} id='services'>
        <h1>Services</h1>
        <div className={styles.cards}>
            <Card name="Care" image={carePhoto} serviceNames={care}/>
            <Card name="Careers" image={careersPhoto} serviceNames={careers}/>
        </div>
    </div>
  )
}
