import React from 'react'
import styles from './index.module.css'
import Item from './components/item'

export default function Card(Props) {
  return (
    <div className={styles.container}>
        <div className={styles.topPart}>
            <div className={styles.heading}></div>
            <img src={Props.image} alt='doctor caring child'/>
            <h1>{Props.name}</h1>
        </div>
        <div className={styles.bottomPart}>
            {
                Props.serviceNames.map((item)=>{
                    return(
                        <Item key={item.id} name={item.service}/>
                    )
                })
            }
                
        </div>
    </div>
  )
}
