import React from "react";
import logo from "../../../../assets/brand/Logo.png"
import styles from "./index.module.css"
import { Link } from "react-router-dom";

export function NavLogo(){
    return(
        <>
            <Link to="/">
            <img className={styles.brandName} src={logo} alt="brand logo"/>
            </Link>
        </>
    );
}