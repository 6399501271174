import React from 'react'
import styles from "./index.module.css"
import homePhoto from "../../assets/images/home-photo.png"
import About from '../about'
import Services from '../services'
import Contact from '../contact'

export default function Home() {
  return (
    <>
        <div className={styles.container} id='home'>
            <div className={styles.left}>
                <span className={styles.heading2}>Connecting</span>
                <span className={styles.careers}>Careers</span>
                <span className={styles.and}>And</span>
                <span className={styles.care}>Care</span>
                <p>Your Trusted Job and Patient Finder</p>
                <a href="mailto:bellstephanie3031@gmail.com" target={"blank"} rel='norefferer'>
                <button>Contact Us</button>
                </a>
            </div>
            <div className={styles.right}>
                <img src={homePhoto} alt='happy girl with the both thumbs up'/>
            </div>
        </div>
        <About/>
        <Services />
        <Contact />
    </>
  )
}
