import React, { useEffect, useState } from "react";
import styles from "./index.module.css"
import { NavMenu } from "./components/NavMenu";
import { NavLogo } from "./components/NavLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useWindowSize } from "@uidotdev/usehooks";

export function Navbar(){
    const [isClicked, setIsClicked] = useState(false);
    
    const screenSize = useWindowSize();
    useEffect(() => {
        setIsClicked(false)
      },[screenSize]);
    
    return(
        <>
         <nav className={styles.navbar}>
            <NavLogo />
            <NavMenu condition={isClicked}/>
            <FontAwesomeIcon className={styles.menuBars} onClick={()=>{
                isClicked ? setIsClicked(false) : setIsClicked(true)
            }} icon={faBars}/>
         </nav>
        </>
    );
};