import React from 'react'
import styles from './index.module.css'

export default function Box(Prop) {
  return (
    <div className={styles.box}>
        <img src={Prop.image} key={Prop.key} alt={Prop.alt}/>
    </div>
  )
}
