import React from 'react'
import styles from './index.module.css'

export default function Item(Props) {
  return (
    <div className={styles.item}>
        <div className={styles.circle}></div>
        <p>{Props.name}</p>
    </div>
  )
}
