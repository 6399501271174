import React from 'react'
import styles from './index.module.css'
import Icon from './components/icon'
import {Link} from 'react-scroll'
import {faFacebookF, faInstagram} from '@fortawesome/free-brands-svg-icons'

export default function Contact() {
  return (
    <>
        <div className={styles.container} id='contact'>
            <div className={styles.privacy}>
                <h1 className={styles.heading}>Privacy Policy & Terms</h1>
                <p>Privacy and Policy</p>
                <p>Terms and Condition</p>
            </div>

            <div className={styles.links}>
                <h1 className={styles.heading}>Quick Links</h1>
                <div className={styles.allLinks}>
                    <div>
                        <Link className={styles.link} to='home' spy={true} smooth={true} offset={50} duration={1500}>Home</Link>
                        <Link className={styles.link} to='services' spy={true} smooth={true} offset={50} duration={1000}>Services</Link>
                    </div>
                    <div>
                        <Link className={styles.link} to='about' spy={true} smooth={true} offset={50} duration={500}>About</Link>
                        <Link className={styles.link} to='contact' spy={true} smooth={true} offset={50} duration={250}>Contact</Link>
                        {/* <p>Blog</p> */}
                    </div>
                </div>
            </div>

            <div className={styles.contact}>
                <h1 className={styles.heading}>Contact Us</h1>
                <div className={styles.contacts}>
                    <div>
                        <p style={{fontWeight: 'bold'}}>Office:</p>
                        <p>89 Willis Bedford,</p>
                        <p>Ohio 44146 PO Box 46574</p>
                    </div>

                    <div>
                        <p style={{fontWeight: 'bold'}}>Phone:</p>
                        <p>216-402-8409</p>
                    </div>

                    <div>
                        <p  style={{fontWeight: 'bold'}}>Email:</p>
                        <p>bellstephanie3031@gmail.com</p>
                    </div>
                </div>
            </div>
            <div className={styles.connect}>
                <h1 className={styles.heading}>Connect With Us</h1>
                <div className={styles.icons}>
                    <Icon className={styles.icon} icon={faFacebookF} link="https://www.facebook.com/stephanie.dubose.56"/>
                    <Icon className={styles.icon} icon={faInstagram} link="https://www.instagram.com/bell3031stephanie/"/>
                    {/* <Icon icon={faLinkedinIn} link="https://www.facebook.com/habibur302"/>
                    <Icon icon={faYoutube} link="https://www.facebook.com/habibur302"/> */}
                </div>
            </div>
        </div>
        <div className={styles.footer}>
            <span>© 2024 | All rights reserved</span>
        </div>
    </>
  )
}
